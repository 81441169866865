<template>
  <v-layout v-if="((this.organization && this.organization.payment_widget) || this.$context.user._isAdmin)" column wrap>
    <v-flex xs12 md12>
      <form class="payform-tbank">
        <v-select 
          label="Услуга"
          v-model="service"
          :items="services"
          item-text="name"
          return-object
          :menu-props="{ offsetY : true }"
          class="payform-tbank-row"
          :error-messages="errorMessages.service"
        >
          <template slot="item" slot-scope="{ item }">
            {{ item.name }}
          </template>
        </v-select>
        <v-text-field 
          label="Количество"
          class="payform-tbank-row"
          v-model="model.quantity"
          :error-messages="errorMessages.quantity"
        />
        <v-text-field
          label="Сумма заказа"
          class="payform-tbank-row"
          v-model="model.amount"
          disabled
        />
        <v-text-field
          label="ФИО плательщика"
          class="payform-tbank-row"
          v-model="model.name"
          :error-messages="errorMessages.name"
        />
        <v-text-field
          label="E-mail"
          class="payform-tbank-row"
          v-model="model.email"
          :error-messages="errorMessages.email"
        />
        <v-text-field
          label="Контактный телефон"
          class="payform-tbank-row"
          v-model="model.phone"
          :error-messages="errorMessages.phone"
        />
        <v-btn
          flat
          class="payform-tbank-row payform-tbank-btn" 
          @click="submit"
        >
          Оплатить
        </v-btn>
    </form>

    <form class="payform-tbank" name="payform-tbank" id="payform-tbank">
      <input class="payform-tbank-row" type="hidden" name="terminalkey" value="1726742111726">
        <input class="payform-tbank-row" type="hidden" name="frame" value="false">
        <input class="payform-tbank-row" type="hidden" name="language" value="ru">
        <input class="payform-tbank-row" type="hidden" name="receipt" value="">
        <input class="payform-tbank-row" type="hidden" placeholder="Количество" name="quantity" :value="model.quantity" required>
        <input class="payform-tbank-row" type="hidden" placeholder="Сумма заказа" name="amount" :value="model.amount" disabled>
        <input class="payform-tbank-row" type="hidden" placeholder="Номер заказа" name="order">
        <input class="payform-tbank-row" type="hidden" placeholder="Описание заказа" name="description" :value="service.description" disabled>
        <input class="payform-tbank-row" type="hidden" placeholder="ФИО плательщика" name="name" :value="model.name" required>
        <input class="payform-tbank-row" type="hidden" placeholder="E-mail" name="email" :value="model.email" required>
        <input class="payform-tbank-row" type="hidden" placeholder="Контактный телефон" name="phone" :value="model.phone" required>
    </form>
    </v-flex>
  </v-layout>
</template>

<script>
import { required, numeric, minValue, email } from 'vuelidate/lib/validators';
import ErrorMessagesMixin from '../../../../app/components/mixins/error-messages-mixin';

export default {
  name: 'app-pay-widget',

  mixins: [ErrorMessagesMixin],

  props: {
    organization: {
      type: Object
    }
  },

  validations() {
    return {
      service: {
        required,
      },
      model: {
        quantity: {
          required,
          numeric,
          minValue: minValue(1)
        },
        name: {
          required
        },
        email: {
          required,
          email
        },
        phone: {
          required
        }
      },
      validationGroup: [
        'service',
        'model.quantity', 
        'model.name', 
        'model.phone', 
        'model.email'
      ]
    };
  },

  data() {
    return {
      service: {},
      model: {
        quantity: 1
      },
      services: []
    }
  },

  watch: {
    model: {
      handler() {
        if (parseInt(this.service.cost) && parseInt(this.model.quantity)) {
          this.model.amount = parseInt(this.service.cost) * parseInt(this.model.quantity);
        }
      },
      deep: true
    },
    service: {
      handler() {
        if (parseInt(this.service.cost) && parseInt(this.model.quantity)) {
          this.model.amount = parseInt(this.service.cost) * parseInt(this.model.quantity);
        }
      },
      deep: true
    }
  },

  async mounted() {
    this.services = (await this.$http.get('/api/v1/main/finances/pay_settings')).data;

    let script = document.createElement('script')
    script.setAttribute('src', 'https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js')
    document.head.appendChild(script);
  },

  methods: {
    async submit() {
      const isValid = await this.validate();
      if (!isValid) {
        return;
      }

      const TPF = document.getElementById("payform-tbank");
      const { description, amount, email, phone, receipt } = TPF;
      
      if (receipt) {
        if (!this.service.name || !this.model.quantity) {
          return alert("Необходимо выбрать услугу и указать кол-во");
        } else if (!email.value && !phone.value) {
          return alert("Поле E-mail или Phone не должно быть пустым");
        }

        TPF.receipt.value = JSON.stringify({
          "EmailCompany": "dir@mstaff.ru",
          "Taxation": "usn_income",
          "FfdVersion": "1.2",
          "Items": [
            {
              "Name": this.service.name,
              "Description": this.service.name,
              "Price": this.service.cost,
              "Quantity": this.model.quantity,
              "Amount": this.model.amount + '00',
              "PaymentMethod": "full_prepayment",
              "PaymentObject": "service",
              "Tax": "none",
              "MeasurementUnit": "pc"
            }
          ]
        });
      }
      pay(TPF);
    }
  }
}
</script>

<style lang="less">
.app-pay-widget {
  width: 100%;
  padding-left: 0px;
}

.payform-tbank {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 2px auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 250px;
}
.payform-tbank-row {
  padding: 15px;
  margin: 2px;
  border-radius: 4px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: 1px solid #DFE3F3;
  
  outline: none;
  background-color: #DFE3F3;
  font-size: 15px;
}
.payform-tbank-row:not(:last-child) {
  padding-bottom: 0;
}

.payform-tbank-row:focus {
  background-color: #FFFFFF;
  border: 1px solid #616871;
  border-radius: 4px;
}
.payform-tbank-btn {
  background-color: #FBC520;
  border: 1px solid #FBC520;
  color: #3C2C0B;
}
.payform-tbank-btn:hover {
  background-color: #FAB619;
  border: 1px solid #FAB619;
}

</style>