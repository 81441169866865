<template>
  <div :class="$options.name">
    <v-container>
      <v-layout row wrap style="padding: 0 24px;">
        <v-flex xs12>
          <v-btn
            v-for="(tab, index) of tabs"
            :key="index"
            class="v-btn--text-transform-none ml-0" 
            :color="setBtnColor(tab)"
            :depressed="!!currentTab === tab"
            @click="currentTab = tab"
          >
            {{ tab.name }}
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout>
        <common-balance v-if="currentTab && currentTab.id === 'balance'" />
        <pay-widget :organization="organization" v-if="currentTab && currentTab.id === 'pay-widget'" />
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import CommonBalance from './balance.vue';
import PayWidget from './pay-widget.vue';

export default {
  name: 'app-common-balance',

  components: {
    CommonBalance,
    PayWidget
  },

  data() {
    return {
      organization: {},
      currentTab: null,
      tabs: []
    }
  },
  
  async mounted() {
    this.organization = (await this.$http.get(`/api/v1/main/organizations/${window.localStorage['organization']}`)).data;
    this.tabs.push({ id: 'balance', name: 'Баланс' });
    if ((this.organization && this.organization.payment_widget) || this.$context.user._isAdmin) {
      this.tabs.push({ id: 'pay-widget', name: 'Пополнение' });
    }
    this.currentTab = this.tabs[0];
  },

  methods: {
    setBtnColor(tab) {
      if (this.currentTab && this.currentTab.id === tab.id) {
        return 'primary';
      } else {
        return '';
      }
    }
  }
}
</script>